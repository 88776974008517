
$(document).foundation();

//menu slide-toogle
$('#toggle-menu').on(
'show.zf.dropdownmenu', function() {
var dropdown = $(this).find('.is-active .is-dropdown-submenu');
dropdown.css('display', 'none');
dropdown.slideToggle('fast');
});
$('#toggle-menu').on(
'hide.zf.dropdownmenu', function() {
var dropdown = $(this).find('.is-active .is-dropdown-submenu');
dropdown.css('display', 'inherit');
dropdown.slideToggle('fast');
});

//back to top
$(document).ready(function () {
$('.anchorlink').click(function () {
$("html, body").animate({
scrollTop: 0
}, 600);
return false;
});
});




    $(document)
    .on('click', 'a[href*="#"]', function() {
      if ( this.hash && this.pathname === location.pathname ) {
        $.bbq.pushState( '#/' + this.hash.slice(1) );
        return false;
      }
    })
    .ready(function() {
    
        setTimeout(function() {

      $(window).bind('hashchange', function(event) {
        var tgt = location.hash.replace(/^#\/?/,'');
        if ( document.getElementById(tgt) ) {
          $.smoothScroll({scrollTarget: '#' + tgt});
        }
      });
      
      }, 1000);
      
      $(window).trigger('hashchange');
    });




